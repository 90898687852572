<template>
    <div class="report">
      <div class="page-header">
        <h1>{{ $t('Report') }} > {{$t('Yearly/ Monthly/ Daily Report') }} </h1>
      </div>
      <h2 class="subheader">{{ $t('Daily Report') }}</h2>
      <el-select v-model="dateSelect">
        <el-option label="Daily" value="Daily"></el-option>
        <el-option label="Monthly" value="Monthly"></el-option>
        <el-option label="Yearly" value="Yearly"></el-option>
      </el-select>
  
      <div class="charts-cards">
        <div class="chart-inner">
          <h2>{{ $t('Total Sales') }}</h2>
          <TotalSales />
        </div>
        <div class="chart-inner">
          <h2>{{ $t('Total Shoppers') }}</h2>
          <TotalCustomers />
        </div>
        <div class="chart-inner">
          <h2>{{ $t('Total Orders') }}</h2>
          <TotalOrders />
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { mapState, mapActions } from 'vuex';
  import TotalSales from '@/components/dashboard/TotalSales.vue';
  import TotalOrders from '@/components/dashboard/TotalOrders.vue';
  import TotalCustomers from '@/components/dashboard/TotalCustomers.vue';
  
  export default {
    name: 'Report',
    components: {
      TotalSales,
      TotalOrders,
      TotalCustomers,
    },
    data(){
      return {
        'dateSelect': 'Daily',
      };
    },
    computed: mapState({
     apiUrl: state => state.apiUrl,
   }),
  }
  </script>
  
  <style scoped lang="scss">
  .subheader{
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .charts-cards{
    display: flex;
    flex-wrap: wrap;
  }
  .chart-inner{
    -webkit-box-flex: 0;
    -ms-flex: 0 0 30%;
    flex: 0 0 30%;
    margin-right: 10px;
    margin-top: 50px;
    background-color: white;
    padding: 20px;
  }
  </style>